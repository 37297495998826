/* eslint-disable no-underscore-dangle */
import { Injectable, NgZone } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Const } from './const';
import { StorageWrapper } from './storage-wrapper';
import { Events } from './events';
import { Environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class LocationTracker {

  private watchId: number;
  private dummyWatch: any;
  private _isActive: boolean;
  private readonly getGpsOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  };

  private gpsData = {
    lat: Environment.mapInitialStateLat,
    lng: Environment.mapInitialStateLng,
    accuracy: 0,
    heading: 0
  };

  constructor(
    public zone: NgZone,
    public platform: Platform,
    public events: Events,
    public storageWrapper: StorageWrapper
  ) {
    this.inactive();
  }

  isActive(): boolean { return this._isActive; }
  getLat(): number { return this.gpsData.lat; }
  getLng(): number { return this.gpsData.lng; }
  getAccuracy(): number { return this.gpsData.accuracy; }
  getHeading(): number { return this.gpsData.heading; }

  public stopTracking() {
    if (this.watchId) {
      this.inactive();
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
    }
  }

  public dummyStopTracking() {
    if (this.dummyWatch == null) { return; }
    this.dummyWatch.unsubscribe();
  }

  /**
   * ユーザーの位置情報を監視開始
   *
   * @returns 画面出し分け用の文字列
   */
  public startTracking(): Observable<string> {
    return new Observable<string>((res) => {
      let isFirstErrorOccurred = true;
      let isFirstSuccess = true;
      if (navigator.geolocation) {
        if (this.watchId) {
          navigator.geolocation.clearWatch(this.watchId);
          this.watchId = null;
        }
        this.watchId = navigator.geolocation.watchPosition((position) => {
          this.active();
          this.gpsData.lat = position.coords.latitude;
          this.gpsData.lng = position.coords.longitude;
          this.gpsData.accuracy = position.coords.accuracy;
          this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);

          if (isFirstSuccess) {
            isFirstSuccess = false;
            isFirstErrorOccurred = false;
            res.next(Const.SUCCESS_GET_USER_POSITION);
          }
        }, (error: GeolocationPositionError) => {
          this.inactive();

          // 初回だけエラー通知を行う
          if (isFirstErrorOccurred) {
            isFirstErrorOccurred = false;
            if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
              res.next(Const.FAILED_NO_PERMISSION);
            } else {
              res.next(Const.FAILED_GET_USER_POSITION);
            }
          }
        }, this.getGpsOptions);
      } else {
        res.next(Const.ERROR_NOT_SUPPORT);
      }
    });
  }

  /**
   * GPSが測位できていない場合の代理関数。初期値のGeolocation情報を返却する。
   * GPSが測位できない場合でもNIMOが動作するようにイベントを送り続ける必要がある
   */
  private dummyStartTracking() {
    if (this.dummyWatch) {
      if (!this.dummyWatch.closed) {
        this.dummyWatch.unsubscribe();
      }
    }
    this.dummyWatch = interval(2000).subscribe(() => {
      this.storageWrapper.getUserId().then((userId) => {
        if (userId == null) {
          return; //初回ログイン時はuserIdが不明なため、無視する
        }

        this.gpsData.lat = Environment.mapInitialStateLat;
        this.gpsData.lng = Environment.mapInitialStateLng;
        this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);
      });
    });
  }

  private active() {
    this._isActive = true;
    this.dummyStopTracking(); //実際のGPSが利用可能な状態ならばダミーGPSは使用しない
  }

  private inactive() {
    this._isActive = false;
    this.dummyStartTracking();
  }
}
