import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class IosAndroidCheck {

    constructor(
        public platform: Platform
    ) {
    }
    /**
     * 端末OSがiOSか確認
     *
     * @returns true：ios false: android
     */
    public checkIsIos(): boolean {
        return this.platform.is('ios');
    }

    /**
     * 端末OSがAndroidか確認
     *
     * @returns 判定結果
     */
    public checkIsAndroid(): boolean {
        return this.platform.is('android');
     }
}
