import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { LanguageSetup } from '../../services/language-setup';

import { TranslateService } from '@ngx-translate/core';
import { LogUtil } from '../../utilities/log-util';
import { Environment } from 'src/environments/environment';
import { IosAndroidCheck } from 'src/app/services/ios-android-check.service';
import { CompTroubleComponent } from 'src/app/components/comp-trouble/comp-trouble';
import { Const } from 'src/app/services/const';
import { OpenWindowService } from 'src/app/services/open-window.service';


@Component({
  selector: 'page-m1-sorry-but',
  templateUrl: 'm1-sorry-but.html',
  styleUrls: ['m1-sorry-but.scss']
})
export class M1SorryButPage implements OnInit {
  @Input() MODAL_TYPE: string;
  @ViewChild(CompTroubleComponent) compTrouble: CompTroubleComponent;

  public static readonly MODAL_ID = "M1SorryButPage";
  public isIos = false;
  public typeConst = Const;
  public isSuccessGetUserPosition = false;
  public isFailedGetUserPosition = false;
  public isFailedNoPermission = false;
  public isMenu = false;

  /** 環境ステータス. */
  protected envStatus: string;
  private logger: LogUtil = new LogUtil("18", "SorryBut");

  constructor(public navCtrl: NavController,
    public langSetup: LanguageSetup,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private osCheck: IosAndroidCheck,
    private openWindowService: OpenWindowService
  ) {
    this.logger.pageLog();
  }

  ngOnInit() {
    this.envStatus = Environment.type;
    this.isIos = this.osCheck.checkIsIos();
    this.isSuccessGetUserPosition = this.MODAL_TYPE === Const.SUCCESS_GET_USER_POSITION;
    this.isFailedGetUserPosition = this.MODAL_TYPE === Const.FAILED_GET_USER_POSITION;
    this.isFailedNoPermission = this.MODAL_TYPE === Const.FAILED_NO_PERMISSION;
    if (this.isSuccessGetUserPosition) {
      this.closeSuccessModal();
    }
  }

  /**
   * モーダルを閉じる
   * 閉じたボタン名を呼び出し元に返す
   */
  public onTapButton(status: string) {
    let tapButtonName: string;

    if (status === this.typeConst.RETRY_GET_USER_POSITION_BUTTON) {
      tapButtonName = this.typeConst.RETRY_GET_USER_POSITION_BUTTON;
    } else if (status === this.typeConst.SELECT_PU_STATION_FROM_MAP_BUTTON) {
      tapButtonName = this.typeConst.SELECT_PU_STATION_FROM_MAP_BUTTON;
    }

    this.modalCtrl.dismiss(tapButtonName, null, M1SorryButPage.MODAL_ID);
  }

  /**
   * GPS設定方法URLへ遷移
   */
  public tapGpsGuide(): void {
    if(this.osCheck.checkIsIos()){
      this.openWindowService.openWindow(Const.IOS_GPS_GUIDE_URL);
    }else if(this.osCheck.checkIsAndroid()){
      this.openWindowService.openWindow(Const.ANDROID_GPS_GUIDE_URL);
    }else{
      this.openWindowService.openWindow(Const.GPS_GUIDE_URL);
    }
  }

  /**GPS取得成功モーダルを閉じる */
  private closeSuccessModal(): void {
    const closeModalTime = 3000;

    const openingSuccessModal = setTimeout(() => {
      clearTimeout(openingSuccessModal);
      this.modalCtrl.dismiss(this.typeConst.CLOSE_BUTTON, null, M1SorryButPage.MODAL_ID);
    }, closeModalTime);
  }

}
