import { Component } from '@angular/core';
import { Const } from 'src/app/services//const';
import { OpenWindowService } from 'src/app/services/open-window.service';

@Component({
    selector: 'app-comp-trouble',
    templateUrl: 'comp-trouble.html',
    styleUrls: ['comp-trouble.scss']
})

export class CompTroubleComponent {

    constructor(
        private openWindowService: OpenWindowService
    ) {}

    /** 電話番号ボタン押下時 */
    public tapCallCenterNumber(): void {
        this.openWindowService.callPhone(Const.CALL_CENTER_NUMBER);
    }
}
