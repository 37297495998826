import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { LanguageSetup } from '../../services/language-setup';
import { ModalControl } from 'src/app/services/modal-control';

import { TranslateService } from '@ngx-translate/core';
import { Const } from 'src/app/services/const';
import { Environment } from 'src/environments/environment';
import { IosAndroidCheck } from 'src/app/services/ios-android-check.service';
import { HttpRequestor } from 'src/app/services/http-requestor';
import { CompTroubleComponent } from 'src/app/components/comp-trouble/comp-trouble';

@Component({
  selector: 'app-page-error-modal',
  templateUrl: 'error-modal.html',
  styleUrls: ['error-modal.scss']
})
export class ErrorComponent implements OnInit {
  public static readonly MODAL_ID = 'ErrorComponent';

  @ViewChild(CompTroubleComponent) compTrouble: CompTroubleComponent;
  @Input() title: string;
  @Input() message: string;
  @Input() isCallCenter: boolean;

  public isMenu = false;
  public isIos = false;
  public errorCode: number;
  /** 環境ステータス. */
  protected envStatus: string;

  constructor(
    public navCtrl: NavController,
    public langSetup: LanguageSetup,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private modalControl: ModalControl,
    private osCheck: IosAndroidCheck,
    private httpReq: HttpRequestor
    ) {}

  ngOnInit() {
    this.envStatus = Environment.type;
    this.isIos = this.osCheck.checkIsIos();
    this.errorCode = this.httpReq.errorCode;
  }

  onTapButton() {
    this.modalControl.stopModal(Const.MODAL_TYPE_ERROR);
  }

}
