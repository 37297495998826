/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { DoStationCodes } from './servicer/models/do-station-codes';
import { Const } from './const';
import { Keyword } from './csv-loader';
import { Router } from '@angular/router';
import { ControlOvserver } from './controlOvserver';
import { UserDispatchConfirmationV2Response } from '../providers/servicer/models/user-dispatch-confirmation-v2-response';


@Injectable({
  providedIn: 'root'
})
export class TransitionStatusService {

  //乗車地選択画面
  public meetPointScreen = {
    url: 'select-meeting-point',
    screenType: Const.START_SCREEN_VIEW_TYPE_PU_NEAR_STOP_POINT
  };

  //降車地選択＿選択画面
  public landmarkScreen = {
    url: 'landmark',
    screenType: Const.START_SCREEN_VIEW_TYPE_DO_MAIN_STOP_POINT
  };

  //降車地選択＿ほか降車地選択画面
  public otherDropScreen = {
    url: 'other-drop-point',
    screenType: ''
  };

  //降車地選択＿頭文字（行）選択画面
  public initialScreen = {
    url: 'initial',
    screenType: Const.START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL_COLUMN
  };

  //降車地選択＿住所（大字）選択画面
  public addressScreen = {
    url: 'address',
    screenType: Const.START_SCREEN_VIEW_TYPE_DO_CHOOSE_ADDRESSS_LARGE
  };

  //降車地選択＿履歴選択画面
  public historyScreen = {
    url: 'history',
    screenType: ''
  };

  //降車地選択＿決定画面（地図）
  public dropPointScreen = {
    url: 'selectDropPoint',
    screenType: Const.START_SCREEN_VIEW_TYPE_DO_MAP_STOP_POINT,
    selectDoStationCode: ''
  };

  //配車予約＿乗降車地確認画面
  public rootConfirmScreen = {
    url: 'root-confirm',
    screenType: ''
  };

  //配車予約＿乗車人数選択画面
  public selectNum = {
    url: 'select-number-of-people',
    screenType: ''
  };

  //配車予約＿タクシー予約確認画面
  public reserveConfirmScreen = {
    url: 'reserve-confirm',
    screenType: ''
  };

  //配車予約＿料金詳細画面
  public feeDetailsScreen = {
    url: 'fee-details',
    screenType: ''
  };

  //乗車前＿タクシー予約完了画面
  public bookingConfirmedScreen = {
    url: 'booking-confirmed',
    screenType: ''
  };

  //乗車前＿車両到着画面
  public rideOnScreen = {
    url: 'ride-on',
    screenType: ''
  };

  //乗車中－後＿発進画面
  public takeOffCarScreen = {
    url: 'take-off-vehicle',
    screenType: ''
  };

  //乗車中-後_乗車中、降車地到着、目的地到着画面
  public onBoadScreen = {
    url: 'on-boad',
    screenType: ''
  };

  //ホーム＿メニュー画面
  public menuScreen = {
    url: 'home',
    screenType: ''
  };

  //ログイン＿ログイン画面
  public loginScreen = {
    url: 'login',
    screenType: ''
  };

  //ホーム画面（ログイン後）
  public entranceScreen = {
    url: 'entrance',
    screenType: ''
  };

  //配車予約＿3分経過
  public reserveTimeOutScreen = {
    url: 'reserve-time-out',
    screenType: ''
  };

  //最後のページ数を記録
  public pageNumList = {
    pageNumberInitialSpot: 0,
    pageNumberInitialSpotFromKeyword: 0,
    pageNumberSmallAddress: 0,
    pageNumberLargeAddress: 0,
    pageNumberHistory: 0
  };

  //ユーザーの予約情報
  private travelInfo: UserDispatchConfirmationV2Response;

  // 通知済みフラグ(車両またはユーザが乗車地到着)
  private isNotified = false;

  // 位置情報モーダルOnOffフラグ
  private isOpenGpsModal = true;

  private polyLine: any;

  //乗車地のコード
  private puStationCode: string;
  //降車地のコード
  private doStationCode: string;

  //ユーザーのリクエスト情報
  private requestInfo: any;

  //ユーザーが選んだ頭文字（あかさ...）
  private initialColumn: number;

  //ユーザーが選んだ頭文字（あいう...）
  private initial: number;

  //ユーザーが選んだキーワード
  private keyWrod: Keyword;

  //ユーザーが選んだ住所（大字）
  private largeAdress: string;

  //ユーザーが選んだ住所（小字）
  private smallAdress: string;

  //ユーザーが選んだ乗車地からいける降車地一覧
  private doStationCodes: DoStationCodes;

  //メニュー画面から戻るときの画面のURL
  private openScreenUrl: string;

  //userIcon
  private userIcon: any;

  //タイマー
  private timer: any;

  //予約確定タイマー時間（3分）
  private readonly reserveLimitTime = 180000;

  //travelInfoの初期化
  private readonly initTravelInfo = {
    /** 結果. */
    result: 0,

    /** メッセージ. */
    message: '',

    /** 車両ID. */
    vehicle_id: 0,

    /** 人数. */
    num: 0,

    /** MP ID. */
    mp_id: '',

    /** MP 名称. */
    mp_name: '',

    /** MP アイコン画像URL. */
    mp_icon_img_url: '',

    /** MP 円形アイコン画像URL. */
    mp_icon_img_circ_url: '',

    /** MP 画像URL. */
    mp_img_url: '',

    /** MP 緯度. */
    mp_lat: 0,

    /** MP 経度. */
    mp_lon: 0,

    /** DP ID. */
    dp_id: '',

    /** DP 名称. */
    dp_name: '',

    /** DP アイコン画像URL. */
    dp_icon_img_url: '',

    /** DP 円形アイコン画像URL. */
    dp_icon_img_circ_url: '',

    /** DP 画像URL. */
    dp_img_url: '',

    /** DP 緯度. */
    dp_lat: 0,

    /** DP 経度. */
    dp_lon: 0,

    /** 車両名. */
    vehicle_name: '',

    /** 車両アイコン画像URL. */
    vehicle_icon_img_url: '',

    /** 車両緯度. */
    vehicle_lat: 0,

    /** 車両経度. */
    vehicle_lon: 0,

    /** 車両方向. */
    vehicle_rotation: 0,

    /** 車両番号. */
    vehicle_number: '',

    /** 車種. */
    vehicle_model: '',

    /** 車両の画像. */
    vehicle_img_url: '',

    /** ワンタイムID. */
    onetime_id: 0,

    /** シートベルトロック. */
    seatbelt_lock: 0,

    /** ドアロック. */
    door_lock: 0,

    /**　AD連携 */
    ad_mode: false,

    /** 増加時間. */
    extended_time: 0,

    /** 車両ルート（ポリライン）. */
    vehicle_polyline: '',

    /** MP 出発時間. */
    mp_departure_time: 0,

    /** MP 到着許容時間(分). */
    mp_allowance_minute: 0,

    /** MP 到着時間. */
    mp_time: 0,

    /** DP 到着時間. */
    dp_time: 0,

    /** 相乗り情報. */
    carpool: null,

    /** ユーザステータス. */
    user_status: 0,

    /** 歩行ナビ情報（現在地～乗車地）. */
    walking_mp: null,

    /** 歩行ナビ情報（降車地～目的地）. */
    walking_dp: null,

    /** トータル乗車時間（分）. */
    total_riding_minute: 0,

    /** 残乗車時間（分）. */
    user_riding_minute: 0,

    /** 車両MP到着残時間（分）. */
    vehicle_mp_arrived_minute: 0,

    /** 車両MPトータル到着残時間（分）. */
    vehicle_mp_total_arrived_minute: 0,

    /** トータル乗車距離. */
    total_boarding_distance: 0,

    /** 車両MP待機時間（秒）. */
    vehicle_mp_wait_sec: 0,

    /** 車両MP待機残時間（秒）. */
    vehicle_mp_leave_sec: 0,

    /** 車両電話番号 */
    vehicle_phone_number: '',

    /** 予約料金 */
    reserve_fare: 0,

    /** 料金詳細 */
    fare_detail: null,

    /**ユーザーID */
    userId: 0,

    /**合計乗車人数 */
    totalPeople: '',
  } as UserDispatchConfirmationV2Response;

  private isOpenOtherPageFlag = false;
  private isBackEntrancePageFlag = false;

  constructor(
    private router: Router,
    private controlOvserver: ControlOvserver
  ) {
    //ユーザーの予約情報
    this.travelInfo = this.initTravelInfo;
  }

  public clearAllInfo() {
    //乗車地選択画面
    this.meetPointScreen = {
      url: 'select-meeting-point',
      screenType: Const.START_SCREEN_VIEW_TYPE_PU_NEAR_STOP_POINT
    };

    //降車地選択＿選択画面
    this.landmarkScreen = {
      url: 'landmark',
      screenType: Const.START_SCREEN_VIEW_TYPE_DO_MAIN_STOP_POINT
    };

    //降車地選択＿ほか降車地選択画面
    this.otherDropScreen = {
      url: 'other-drop-point',
      screenType: ''
    };

    //降車地選択＿頭文字（行）選択画面
    this.initialScreen = {
      url: 'initial',
      screenType: Const.START_SCREEN_VIEW_TYPE_DO_CHOOSE_INITIAL_COLUMN
    };

    //降車地選択＿住所（大字）選択画面
    this.addressScreen = {
      url: 'address',
      screenType: Const.START_SCREEN_VIEW_TYPE_DO_CHOOSE_ADDRESSS_LARGE
    };

    //降車地選択＿履歴選択画面
    this.historyScreen = {
      url: 'history',
      screenType: ''
    };

    //降車地選択＿決定画面（地図）
    this.dropPointScreen = {
      url: 'selectDropPoint',
      screenType: Const.START_SCREEN_VIEW_TYPE_DO_MAP_STOP_POINT,
      selectDoStationCode: ''
    };

    //配車予約＿乗降車地確認画面
    this.rootConfirmScreen = {
      url: 'root-confirm',
      screenType: ''
    };

    //配車予約＿乗車人数選択画面
    this.selectNum = {
      url: 'select-number-of-people',
      screenType: ''
    };

    //配車予約＿タクシー予約確認画面
    this.reserveConfirmScreen = {
      url: 'reserve-confirm',
      screenType: ''
    };

    //乗車前＿タクシー予約完了画面
    this.bookingConfirmedScreen = {
      url: 'booking-confirmed',
      screenType: ''
    };

    //乗車前＿車両到着画面
    this.rideOnScreen = {
      url: 'ride-on',
      screenType: ''
    };

    //乗車中－後＿発進画面
    this.takeOffCarScreen = {
      url: 'take-off-vehicle',
      screenType: ''
    };

    //乗車中-後_乗車中、降車地到着、目的地到着画面
    this.onBoadScreen = {
      url: 'on-boad',
      screenType: Const.STATE_RIDING
    };

    //ホーム＿メニュー画面
    this.menuScreen = {
      url: 'home',
      screenType: ''
    };

    //ログイン＿ログイン画面
    this.loginScreen = {
      url: 'login',
      screenType: ''
    };

    //配車予約＿3分経過
    this.reserveTimeOutScreen = {
      url: 'reserve-time-out',
      screenType: ''
    };

    this.polyLine = [];

    //最後のページ数を記録
    this.pageNumList.pageNumberHistory = 0;

    this.polyLine = null;

    //乗車地のコード
    this.puStationCode = '';
    //降車地のコード
    this.doStationCode = '';

    //ユーザーのリクエスト情報
    this.requestInfo = null;

    //ユーザーの予約情報
    this.resetTravelInfo();

    //ユーザーが選んだ乗車地からいける降車地一覧
    this.doStationCodes = null;

    // 通知済みフラグ(車両またはユーザが乗車地到着)
    this.isNotified = false;

    // 位置情報モーダルOnOffフラグ
    this.isOpenGpsModal = true;

    //住所関連関連の情報をクリア
    this.clearAdressInfo();

    //頭文字関連関連の情報をクリア
    this.clearInitialInfo();

    this.controlOvserver.clearPostVehiclesApivObserver();
  }

  /**
   * 住所関連関連の情報をクリア
   */
  public clearAdressInfo() {
    //ユーザーが選んだ住所（大字）
    this.largeAdress = '';

    //ユーザーが選んだ住所（小字）
    this.smallAdress = '';

    this.pageNumList.pageNumberLargeAddress = 0;
    this.pageNumList.pageNumberSmallAddress = 0;
  }

  /**
   * 頭文字関連関連の情報をクリア
   */
  public clearInitialInfo() {
    //ユーザーが選んだ頭文字（あかさ...）
    this.initialColumn = 0;

    //ユーザーが選んだ頭文字（あいう...）
    this.initial = 0;

    //ユーザーが選んだキーワード
    this.keyWrod = null;

    //メニュー画面から戻るときの画面のURL
    this.openScreenUrl = '';

    this.pageNumList.pageNumberInitialSpot = 0;
    this.pageNumList.pageNumberInitialSpotFromKeyword = 0;
  }

  /** タクシー予約のときに必要な情報を保管 */
  public setTravelInfo(update: UserDispatchConfirmationV2Response) {
    this.travelInfo = {
      /** 結果. */
      result: update.result ? update.result : this.travelInfo.result,

      /** メッセージ. */
      message: update.message ? update.message : this.travelInfo.message,

      /**車両ID.*/
      vehicle_id: update.vehicle_id ? update.vehicle_id : this.travelInfo.vehicle_id,

      /**人数.*/
      num: update.num ? update.num : this.travelInfo.num,

      /**MPID.*/
      mp_id: update.mp_id ? update.mp_id : this.travelInfo.mp_id,

      /**MP名称.*/
      mp_name: update.mp_name ? update.mp_name : this.travelInfo.mp_name,

      /**MPアイコン画像URL.*/
      mp_icon_img_url: update.mp_icon_img_url ? update.mp_icon_img_url : this.travelInfo.mp_icon_img_url,

      /**MP円形アイコン画像URL.*/
      mp_icon_img_circ_url: update.mp_icon_img_circ_url ? update.mp_icon_img_circ_url : this.travelInfo.mp_icon_img_circ_url,

      /**MP画像URL.*/
      mp_img_url: update.mp_img_url ? update.mp_img_url : this.travelInfo.mp_img_url,

      /**MP緯度.*/
      mp_lat: update.mp_lat ? update.mp_lat : this.travelInfo.mp_lat,

      /**MP経度.*/
      mp_lon: update.mp_lon ? update.mp_lon : this.travelInfo.mp_lon,

      /**DPID.*/
      dp_id: update.dp_id ? update.dp_id : this.travelInfo.dp_id,

      /**DP名称.*/
      dp_name: update.dp_name ? update.dp_name : this.travelInfo.dp_name,

      /**DPアイコン画像URL.*/
      dp_icon_img_url: update.dp_icon_img_url ? update.dp_icon_img_url : this.travelInfo.dp_icon_img_url,

      /**DP円形アイコン画像URL.*/
      dp_icon_img_circ_url: update.dp_icon_img_circ_url ? update.dp_icon_img_circ_url : this.travelInfo.dp_icon_img_circ_url,

      /**DP画像URL.*/
      dp_img_url: update.dp_img_url ? update.dp_img_url : this.travelInfo.dp_img_url,

      /**DP緯度.*/
      dp_lat: update.dp_lat ? update.dp_lat : this.travelInfo.dp_lat,

      /**DP経度.*/
      dp_lon: update.dp_lon ? update.dp_lon : this.travelInfo.dp_lon,

      /**車両名.*/
      vehicle_name: update.vehicle_name ? update.vehicle_name : this.travelInfo.vehicle_name,

      /**車両アイコン画像URL.*/
      vehicle_icon_img_url: update.vehicle_icon_img_url ? update.vehicle_icon_img_url : this.travelInfo.vehicle_icon_img_url,

      /**車両緯度.*/
      vehicle_lat: update.vehicle_lat ? update.vehicle_lat : this.travelInfo.vehicle_lat,

      /**車両経度.*/
      vehicle_lon: update.vehicle_lon ? update.vehicle_lon : this.travelInfo.vehicle_lon,

      /**車両方向.*/
      vehicle_rotation: update.vehicle_rotation ? update.vehicle_rotation : this.travelInfo.vehicle_rotation,

      /**車両番号.*/
      vehicle_number: update.vehicle_number ? update.vehicle_number : this.travelInfo.vehicle_number,

      /**車種.*/
      vehicle_model: update.vehicle_model ? update.vehicle_model : this.travelInfo.vehicle_model,

      /**車両の画像.*/
      vehicle_img_url: update.vehicle_img_url ? update.vehicle_img_url : this.travelInfo.vehicle_img_url,

      /**ワンタイムID.*/
      onetime_id: update.onetime_id ? update.onetime_id : this.travelInfo.onetime_id,

      /**シートベルトロック.*/
      seatbelt_lock: update.seatbelt_lock ? update.seatbelt_lock : this.travelInfo.seatbelt_lock,

      /**ドアロック.*/
      door_lock: update.door_lock ? update.door_lock : this.travelInfo.door_lock,

      /**AD連携 */
      ad_mode: update.ad_mode ? update.ad_mode : this.travelInfo.ad_mode,

      /**増加時間.*/
      extended_time: update.extended_time ? update.extended_time : this.travelInfo.extended_time,

      /**車両ルート（ポリライン）.*/
      vehicle_polyline: update.vehicle_polyline ? update.vehicle_polyline : this.travelInfo.vehicle_polyline,

      /**MP出発時間.*/
      mp_departure_time: update.mp_departure_time ? update.mp_departure_time : this.travelInfo.mp_departure_time,

      /**MP到着許容時間(分).*/
      mp_allowance_minute: update.mp_allowance_minute ? update.mp_allowance_minute : this.travelInfo.mp_allowance_minute,

      /**MP到着時間.*/
      mp_time: update.mp_time ? update.mp_time : this.travelInfo.mp_time,

      /**DP到着時間.*/
      dp_time: update.dp_time ? update.dp_time : this.travelInfo.dp_time,

      /**相乗り情報.*/
      carpool: update.carpool ? update.carpool : this.travelInfo.carpool,

      /**ユーザステータス.*/
      user_status: update.user_status ? update.user_status : this.travelInfo.user_status,

      /**歩行ナビ情報（現在地～乗車地）.*/
      walking_mp: update.walking_mp ? update.walking_mp : this.travelInfo.walking_mp,

      /**歩行ナビ情報（降車地～目的地）.*/
      walking_dp: update.walking_dp ? update.walking_dp : this.travelInfo.walking_dp,

      /**トータル乗車時間（分）.*/
      total_riding_minute: update.total_riding_minute ? update.total_riding_minute : this.travelInfo.total_riding_minute,

      /**残乗車時間（分）.*/
      user_riding_minute: update.user_riding_minute ? update.user_riding_minute : this.travelInfo.user_riding_minute,

      /**車両MP到着残時間（分）.*/
      vehicle_mp_arrived_minute:
        update.vehicle_mp_arrived_minute ? update.vehicle_mp_arrived_minute : this.travelInfo.vehicle_mp_arrived_minute,

      /**車両MPトータル到着残時間（分）.*/
      vehicle_mp_total_arrived_minute:
        update.vehicle_mp_total_arrived_minute ? update.vehicle_mp_total_arrived_minute : this.travelInfo.vehicle_mp_total_arrived_minute,

      /**トータル乗車距離.*/
      total_boarding_distance: update.total_boarding_distance ? update.total_boarding_distance : this.travelInfo.total_boarding_distance,

      /**車両MP待機時間（秒）.*/
      vehicle_mp_wait_sec: update.vehicle_mp_wait_sec ? update.vehicle_mp_wait_sec : this.travelInfo.vehicle_mp_wait_sec,

      /**車両MP待機残時間（秒）.*/
      vehicle_mp_leave_sec: update.vehicle_mp_leave_sec ? update.vehicle_mp_leave_sec : this.travelInfo.vehicle_mp_leave_sec,

      /**車両電話番号 */
      vehicle_phone_number: update.vehicle_phone_number ? update.vehicle_phone_number : this.travelInfo.vehicle_phone_number,

      /**予約料金*/
      reserve_fare: update.reserve_fare ? update.reserve_fare : this.travelInfo.reserve_fare,

      /**料金詳細*/
      fare_detail: update.fare_detail ? update.fare_detail : this.travelInfo.fare_detail,

      /**ユーザーID*/
      userId: update.userId ? update.userId : this.travelInfo.userId,

      /**合計乗車人数*/
      totalPeople: update.totalPeople ? update.totalPeople : this.travelInfo.totalPeople,

    };
  }

  /** タクシー予約のときに必要な情報を取得 */
  public getTravelInfo() {
    return this.travelInfo;
  }

  /** タクシー予約のときに必要な情報を初期化 */
  public resetTravelInfo() {
    this.travelInfo = this.initTravelInfo;
  }

  /** 乗車地コード保管 */
  public setPuStationCode(updated: string) {
    this.puStationCode = updated;
  }

  /** 乗車地コード取得 */
  public getPuStationCode() {
    return this.puStationCode;
  }

  /** 降車地コード保管 */
  public setDoStationCode(updated: string) {
    this.doStationCode = updated;
  }
  /** 降車地コード取得 */
  public getDoStationCode() {
    return this.doStationCode;
  }


  /** 降車地コード一覧保管 */
  public setDoStationCodes(updated: DoStationCodes) {
    this.doStationCodes = updated;
  }

  /** 降車地コード一覧取得 */
  public getDoStationCodes() {
    return this.doStationCodes;
  }

  /** メニュー画面閉じたときの戻る画面のステータス保管 */
  public setOpenScreenUrl(updated: string) {
    this.openScreenUrl = updated;
  }

  /** メニュー画面閉じたときの戻る画面のステータス取得 */
  public getOpenScreenUrl() {
    return this.openScreenUrl;
  }


  //リクエスト情報保管
  public setRequest(updated: any) {
    this.requestInfo = updated;
  }

  public getRequest() {
    return this.requestInfo;
  }

  //頭文字（あかさ...）
  public setInitialColumn(updated: number) {
    this.initialColumn = updated;
  }
  public getInitialColumn() {
    return this.initialColumn;
  }
  //頭文字（あいう...）
  public setInitial(updated: number) {
    this.initial = updated;
  }
  public getInitial() {
    return this.initial;
  }
  //キーワード
  public setKeyWord(updated: Keyword) {
    this.keyWrod = updated;
  }
  public getKeyWord() {
    return this.keyWrod;
  }

  //大字の住所
  public setLargeAdress(updated: string) {
    this.largeAdress = updated;
  }

  public getLargeAdress() {
    return this.largeAdress;
  }

  //小字の住所
  public setSmallAdress(updated: string) {
    this.smallAdress = updated;
  }

  public getSmallAdress() {
    return this.smallAdress;
  }

  //経路ラインの保存
  public setPolyLine(updated: string) {
    this.polyLine = updated;
  }

  public getPolyLine() {
    return this.polyLine;
  }

  //userIconの保存
  public setUserPositionIcon(updated: any) {
    this.userIcon = updated;
  }

  public getUserPositionIcon() {
    return this.userIcon;
  }

  //予約制限時間のタイマーを保存
  public setReserveTimer() {
    this.timer = setTimeout(() => {
      if (this.isOpenOtherPageFlag) {
        this.setOpenScreenUrl(this.reserveTimeOutScreen.url);
      } else {
        this.router.navigate([this.reserveTimeOutScreen.url], { replaceUrl: true });
      }
    }, this.reserveLimitTime);
  }

  public getReserveTimer() {
    return this.timer;
  }
  // 通知済みフラグ(車両またはユーザが乗車地到着)
  public setIsNotified(updated: boolean) {
    this.isNotified = updated;
  }

  public getIsNotified() {
    return this.isNotified;
  }

  // 位置情報モーダルOnOffフラグ
  public setIsOpenGpsModal(updated: boolean) {
    this.isOpenGpsModal = updated;
  }

  public getIsOpenGpsModal() {
    return this.isOpenGpsModal;
  }

  //タイマーをリセットする
  public clearReserveTimer() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  /*** 予約確認画面専用
   * @param updated 設定画面または、料金詳細画面を開いているときは、true
   *                閉じているときは、false
   */
  public setIsOpenOhterPageFlag(updated: boolean) {
    this.isOpenOtherPageFlag = updated;
  }

  /**
   * GPS取得失敗時は、直接「乗車地を選ぶ」画面に遷移するため、
   * 「乗車地を選ぶ」画面の戻るボタン押下時の遷移先の切り分け
   *
   * @param flag true: 「ホーム」画面に遷移　false:　「乗車地を決める」画面に遷移
   */
  public setIsBackEntrancePageFlag(flag: boolean): void {
    this.isBackEntrancePageFlag = flag;
  }

  /**
   *「乗車地を選ぶ」画面の戻るボタン押下時の遷移先の切り替えフラグを返す
   *
   * @returns 「乗車地を選ぶ」画面の戻るボタン押下時の遷移先の切り替えフラグ
   */
  public getIsBackEntrancePageFlag(): boolean {
    return this.isBackEntrancePageFlag;
  }
}
