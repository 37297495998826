import { LanguageSetup } from '../language-setup';
import { HttpRequestor } from '../http-requestor';
import { LocationTracker } from '../location-tracker';
import { interval, Subscription } from 'rxjs';
import { Const } from '../const';
import { UserStatusStrategyInterface } from './user-status.strategy.interface';
import { tap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { TransitionStatusService } from '../transition-status.service';

export class UserStatusPolling implements UserStatusStrategyInterface {
    private callback: any;

    private userId: string;

    /** インターバルのSubscription. */
    private intervalSubscription: Subscription = null;
    private processing = false;
    private readonly intervalTime = 1000;

    constructor(
        public langSetup: LanguageSetup,
        public httpReq: HttpRequestor,
        public locationTracker: LocationTracker,
        private router: Router,
        private transitionStatusService: TransitionStatusService,
    ) {
    }

    start(callback: any, userId: string) {
        this.callback = callback;
        this.userId = userId;

        // 初回の予約一覧取得
        this.execute();

        //一定周期で「getReservations」を実行
        if (!this.intervalSubscription) {
            this.intervalSubscription = interval(this.intervalTime).subscribe(() => this.execute());
        }
    }

    stop() {
        if (this.intervalSubscription) {
            if (!this.intervalSubscription.closed) {
                this.intervalSubscription.unsubscribe();
            }
        }
        this.intervalSubscription = null;
        this.processing = false;
        this.userId = null;
    }

    execute() {
        of(this.processing)
            //APIが実行中ではない場合
            .pipe(filter(() => !this.processing))
            //API実行中フラグを更新　true:実行中
            .pipe(tap(() => this.processing = true))
            //APIを実行中
            .pipe(tap(() => this.getUserInfo()))
            //API実行中フラグを更新　false:実行中ではない
            .pipe(tap(() => this.processing = false))
            .subscribe();
    }


    // eslint-disable-next-line @typescript-eslint/naming-convention
    statusReceiveCallback = (res, code, req_sts, auto) => {
        if (code !== Const.HTTP_REQUEST_CODE_SUCCESS || res === '') {
            this.stop();
            this.transitionStatusService.clearAllInfo();
            this.transitionStatusService.clearReserveTimer();
            this.router.navigate([this.transitionStatusService.loginScreen.url], { replaceUrl: true });
        }
        const json = res;
        if (json.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) { return; }

        this.callback(res);
    };

    private getUserInfo() {
        this.httpReq.postDispatchConfirmationRequest(
            this.userId,
            this.langSetup.lang,
            this.statusReceiveCallback,
            this.locationTracker.getLat(),
            this.locationTracker.getLng(),
            this.locationTracker.getAccuracy()
        );
    }
}
