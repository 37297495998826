import { Injectable } from '@angular/core';
@Injectable({
      providedIn: 'root'
})

export class OpenWindowService {

  /**ページを開くボタン押下.
   * @param url 遷移ページURL
   */
  public openWindow(url: string): void {
    window.open(url, '_blank');
  }

  /**電話番号ボタン押下.
   * @param phoneNumber 電話番号
   */
  public callPhone(phoneNumber: string): void {
    window.location.href = `tel:${phoneNumber}`;
  }
}
